<script>

import SelectGroup from "@/components/forms/select-group";

export default {
    components: {
        SelectGroup,
    },
    data() {
        return {
            statusOptions: [
                { name: 'All', value: 2 },
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            statusSelected: ""
        };
    },
    props: {
        allSelection: {
            default: true
        },
        statusVal: {
            default: ''
        },
        error:{
            
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.statusSelected);
        }
    },
    beforeMount() {
        if (!this.allSelection) {
            this.statusOptions.shift();
        }

        if (this.statusVal) {
            this.statusSelected = this.statusVal
        }
    }
};
</script>

<template>
    <SelectGroup id="status" displayName="Status" v-model="statusSelected" :options="statusOptions" @input="handleInput" :error="error"/>
</template>